import React from "react"
import styled from "styled-components"
import {
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
  } from "react-share";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
  } from "react-share";

const BlogPostBody = ({
  image,
  title,
  category,
  body,
  date,
  location,
}) => {
  const BodyBlogPost = body[0].primary.text
  console.log(location)
  const shareUrl = `https://www.getsajdah.com${location.pathname}`
  return (
    
    <BodyWrapper>
        {
            title &&  <BodyHeader> {title} </BodyHeader>
        }
        {
            date && <Date>{date}</Date>
        }
        <ImageContainer>
            <HeaderImage src={image} alt={title} />
            {
                category && <Category> {category} </Category>
            }
        </ImageContainer>
        <BodyPost>
            {
                BodyBlogPost.map((item,index) => {
                    return (
                        <>
                            {
                                item.type === "paragraph" && (
                                    <p>
                                        {item.text}
                                    </p>
                                )
                            }
                            {
                                item.type === "heading1" && (
                                    <h2>
                                        {item.text}
                                    </h2>
                                )
                            }
                            {
                                item.type === "list-item" && (
                                    <li>
                                        {item.text}
                                    </li>
                                )
                            }
                        </>
                        
                    )
                })
            }
        </BodyPost>
        <ShareLinks>
            <h4>Share this article</h4>
            <IconsWrapper>
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={40} round={true}></FacebookIcon>
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl}>
                <TwitterIcon size={40} round={true}></TwitterIcon>
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon size={40} round={true}></LinkedinIcon>
              </LinkedinShareButton>
            </IconsWrapper>
        </ShareLinks>
    </BodyWrapper>
  )
}

export default BlogPostBody

BlogPostBody.propTypes = {

}

BlogPostBody.defaultProps = {

}


const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top:16px;
  max-width: 800px;
  width:100%;
  margin:40px auto;
  @media (max-width:1280px) {
    padding: 24px 30px;
    justify-content: space-between;
    max-width: 80%;
  }
  @media (max-width:768px) {
    padding: 16px 30px 0;
    max-width:90%;
  }
  .active {
    color: #008e8e;
  }
  @media (max-width:470px) {
    height: 100%;
    padding: 0;
    justify-content: flex-start;
  }
`


const BodyHeader = styled.div`
    font-family: Poppins, sans-serif;
    font-size: 24px;
    line-height: 140%;
    color: #2d2d2d;
    font-weight: 500;
    max-width: 600px;
    text-align: left;
    overflow: hidden;
    position: relative;
    padding-bottom: 16px;
    @media (max-width: 1280px) {
        font-size: 24px;
        max-width: 80%;
    }
`;

const HeaderImage = styled.img`
    max-width:800px;
`

const Category = styled.div`
    font-family: Poppins, sans-serif;
    padding:4px 16px;
    color:white;
    background-color:#008e8e;
    display:inline-block;
    border-radius:8px;
    width:auto;
    text-align:center;
    max-width:100px;
    position:absolute;
    bottom:32px;
    left:16px;
    font-size:14px;
`

const ImageContainer = styled.div`
    position:relative;
    margin:0 auto 40px;
    width:100%;
    @media (max-width:768px) {
      max-width:100%;
      img {
        max-width:100%;
      }
    }
`

const Date = styled.p`
    font-family: Poppins, sans-serif;
    padding:4px 0;
    color: #2d2d2d;
    margin:0 0 12px 0;
    opacity: .7;
`
const BodyPost = styled.div`
`

const ShareLinks = styled.div`
  width: 100%;
  margin:24px auto 40px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  position:relative;
  h4 {
    color: #2d2d2d;
    font-size:24px;
    line-height:32px;
    font-family: Poppins, sans-serif;
    font-weight:500;
  }
  button {
    outline:none;
  }
  @media (max-width: 768px) {
    padding: 0 16px;
    max-width:70%;
    margin:0 auto;
    svg {
      width:24px;
      height:24px;
    }
    h4 {
      margin-bottom:0;
    }
  }
  @media (max-width: 470px) {
    flex-direction:column;
  }
`

const IconsWrapper = styled.div`
  width:100%;
  max-width:180px;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  @media (max-width: 768px) {
    margin-top:24px;
  }
  
`


