import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderInfoPage from "../components/HeaderInfoPages/HeaderInfoPages";
import BlogPostBody from "../components/BlogPostBody/BlogPostBody";

const BlogLPost = props => {
  const postData = props.data.prismicBlogPost.data
  const shareUrl = `https://www.getsajdah.com${props.location.pathname}`
  const descrSeo = postData.body[0].primary.text[0].text

  return (
    <Layout>
      <SEO 
        title={postData?.post_title[0]?.text} 
        description={descrSeo}
        image="/New_Muslims.png"
        url={shareUrl}
        titleTemplate={postData?.post_title[0]?.text}
      />
      <HeaderInfoPage title="Blog Post" />
      <BlogPostBody 
        title={postData?.post_title[0]?.text}
        image={postData?.featured_image?.url}
        category={postData?.category[0]?.text}
        date={postData.date}
        body={postData.body}
        location={props.location}
      />
    </Layout>
  )
}


export const blogPostQuery = graphql`
  query BlogPostQuery($id: String) {
    prismicBlogPost(id: { eq: $id }) {
      id
      data {
        body {
          primary {
            text {
              text
              type
            }
          }
          slice_type
        }
        date
        post_title {
          text
        }
        featured_image {
          url
        }
        category {
          text
        }
      }
    }
  }
`

export default BlogLPost;